<template>
  <section class="level-form-section">
  <v-container>
    <!-- Form for adding new levels -->
    <v-card class="form-card">
      <v-card-title>Ajouter un niveau</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="addLevel" ref="form" v-model="valid">
          <v-text-field
            v-model="level.name"
            label="Nom"
            :rules="[v => !!v || 'Nom est requis']"
            required
            outlined
            class="styled-input"
          ></v-text-field>
          <v-row>
        <v-col>
      
      <VueEditor
                    v-model="level.description"
                    auto-grow
                    outlined
                    label="Description"
                  
                    placeholder="Description"
                  >
                  </VueEditor>
    </v-col>
    </v-row>
    <v-row>
      <v-col>
          <div class="form-actions">
            <v-btn 
              class="btn" 
              type="submit" 
              color="info" 
              :loading="loading" 
              :disabled="loading"
              v-ripple
            >
              Ajouter un niveau
            </v-btn>
          </div>
        </v-col>
        </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-divider class="my-4"></v-divider>
    <!-- Data table for displaying levels -->
    <v-card >
        <v-card-title>Liste des Niveaux</v-card-title>
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        :search="search"
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="levels"
        :loading="loading"
        item-key="id"
        class="elevation-1"
        :search="search"
      :no-data-text="noDataText"
      >
      
        <template v-slot:item.edit="{ item }">
          <v-icon  @click="openEditDialog(item)" class="edit-icon" v-ripple>mdi-pencil</v-icon>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-icon  @click="openDeleteDialog(item.id)" class="delete-icon" v-ripple>mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <!-- Dialog for editing levels -->
    <v-dialog v-model="isEditDialogOpen" persistent max-width="600px">
      <v-card>
        <v-card-title>Editer un niveau</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="editLevel" ref="editForm">
            <v-text-field
              v-model="level.name"
              label="Nom"
              :rules="[v => !!v || 'Name is required']"
              required
              outlined
              dense
              class="styled-input"
            ></v-text-field>
            <v-textarea
              v-model="level.description"
              label="Description"
              :rules="[v => !!v || 'Description is required']"
              required
              outlined
              dense
              rows="4"
              class="styled-input"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="info" @click="editLevel" :loading="loading" :disabled="loading" v-ripple>Modifier</v-btn>
          <v-btn color="error" @click="closeEditDialog" v-ripple>Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog for deleting levels -->
    <v-row justify="center">
      <v-dialog v-model="isDeleteDialogOpen" max-width="530">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir supprimer le niveau ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
               @click="closeDeleteDialog"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="confirmDeleteLevel">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</section>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css';
import Constant from "@/utils/constants";

import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

export default {
  components: { 
    VueEditor
   },
  data() {
    return {
      level: {
        id: null,
        name: "",
        description: ""
      },
      isEditDialogOpen: false,
      isDeleteDialogOpen: false,
      levelIdToDelete: null,
      valid: false,
      search: '',
      noDataText: 'No categories found',
      levels: [],
      loading: false,
      headers: [
        { text: 'Nom', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: "Modifier", value: "edit", align: "center", sortable: false },
        { text: "Supprimer", value: "delete", align: "center", sortable: false },
      ]
    };
  },
  created() {
    this.fetchLevels();
  },
  methods: {
    validateForm() {
      if (!this.level.name || !this.level.description) {
        alert("All fields are required!");
        return false;
      }
      return true;
    },
    async addLevel() {
       // Trigger validation
     this.$refs.form.validate();

// Check if form is valid
if (!this.valid) {
  console.log("Form validation failed.");
  return;
}
      if (!this.validateForm()) {
        return;
      }
      this.loading = true;
      try {
        const response = await fetch(Constant.QORANI + "library/level/add", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.level),
        });
        const data = await response.json();
        this.levels.push(data);
        this.resetForm();
      } catch (error) {
        console.error('There was an error!', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchLevels() {
      try {
        const response = await fetch(Constant.QORANI + "library/level/getall");
        this.levels = await response.json();
      } catch (error) {
        console.error('There was an error fetching the levels!', error);
      }
    },
   
    openDeleteDialog(levelId) {
      this.levelIdToDelete = levelId;
      this.isDeleteDialogOpen = true;
    },
    async confirmDeleteLevel() {
      this.loading = true;
      try {
        await fetch(Constant.QORANI + `library/level/delete/${this.levelIdToDelete}`, {
          method: 'GET',
        });
        this.levels = this.levels.filter(item => item.id !== this.levelIdToDelete);
      } catch (error) {
        console.error('Failed to delete level:', error);
      } finally {
        this.loading = false;
        this.closeDeleteDialog();
      }
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpen = false;
      this.levelIdToDelete = null;
    },
    openEditDialog(level) {
      this.level = { ...level };
      this.isEditDialogOpen = true;
    },
    async editLevel() {
      if (!this.validateForm()) {
        return;
      }
      this.loading = true;
      try {
        const response = await fetch(Constant.QORANI + `library/level/edit/${this.level.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.level),
        });
        const data = await response.json();
        const index = this.levels.findIndex(item => item.id === this.level.id);
        if (index !== -1) {
          this.levels[index] = data;
        }
        this.closeEditDialog();
      } catch (error) {
        console.error('There was an error!', error);
      } finally {
        this.loading = false;
      }
    },
    closeEditDialog() {
      this.isEditDialogOpen = false;
      this.resetForm();
    },
    resetForm() {
      this.level = {
        id: null,
        name: "",
        description: ""
      };
      this.$refs.form.reset();
      if (this.$refs.editForm) {
        this.$refs.editForm.reset();
      }
    }
  }
};
</script>

<style scoped>
/* Base styles */
.level-form-section {
  padding: 24px;
  background-color: #f4f7f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Card styling for form and data table */
.form-card, .v-card {
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; /* Clean white background */
}

/* Input field styling */
.styled-input {
  margin-bottom: 16px; /* Spacing between fields */
}

/* Button styling */
.v-btn {
  border-radius: 6px;

}

/* Divider styling */
.v-divider {
  margin: 24px 0; /* Increased spacing for dividers */
}

/* Data table styling */
.elevation-1 {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for data table */
  border-radius: 12px; /* Rounded corners */
}

.elevation-1 thead th {
  background-color: #f4f5f7; /* Light gray for headers */
  color: #333; /* Darker text for contrast */
  font-weight: 500; /* Slightly bold */
  border-bottom: 2px solid #e0e0e0; /* Subtle border */
}

/* Icon styling */
.v-icon {
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

/* Search field styling */
.v-text-field[v-model="search"] {
  max-width: 350px; /* Slightly wider search field */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .level-form-section {
    padding: 16px; /* Reduced padding for smaller screens */
  }

  .v-row {
    flex-direction: column; /* Stack elements vertically */
  }

  .v-col {
    width: 100%; /* Full width for each column on small screens */
  }

  .v-data-table {
    font-size: 14px; /* Smaller font size for tables */
  }

  .v-btn {
    width: 100%; /* Full width buttons */
  }
}

@media (min-width: 769px) {
  .v-col {
    max-width: 45%; /* Allow two items per row on larger screens */
    margin: 0 2.5%; /* Horizontal margin for spacing */
  }

  .v-data-table {
    font-size: 16px; /* Default font size for desktop */
  }
}


</style>


